<template>
  <card class="card-chart" no-footer-line>
    <template slot="header" v-if="title">
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="card-category mb-0 text-uppercase">{{ title }}</h5>
      </div>
    </template>
    <div class="chart-area">
      <component v-if="loaded"
                 :is="chartComponent"
                 :chart-data="chartData"
                 :extra-options="chartOptions"
                 :height="height"
      />
      <div v-if="!loaded" class="loadingChart" :style="{minHeight : `${height}px`}">
        <octo-loader/>
      </div>
    </div>
  </card>
</template>

<script>
import {DatePicker} from 'element-ui';
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import {barChartOptionsGradient, lineChartOptionsBlue} from "@/components/Charts/config";
import config from '@/config';
import OctoLoader from "@/components/octo-loader/OctoLoader";

export default {
  name: "BaseChart",
  components: {
    OctoLoader,
    [DatePicker.name]: DatePicker
  },
  data() {
    return {
      datePickerValue: [],
      chartDataFromApi: {
        'labels' : [],
        'data': []
      }

    }
  },
  props: {
    endpoint: {
      type: String,
      required: true
    },
    height: {
      type: Number,
      default: 200,
    },
    title: {
      type: String,
      default: null,
    },
    chartType: {
      type: String,
      default: 'bar',
      description: 'Possible values: bar, line'
    },
  },
  computed: {

    chartData: function () {

      const datasets = [];
      this.$_.each(this.chartDataFromApi.data, (item) => {
        datasets.push({
          label: item.label,
          fill: true,
          borderColor: config.colors.info,
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: config.colors.info,
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: '#2380f7',
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: item.data
        })
      });

      return {
        labels: this.chartDataFromApi.labels,
        datasets: datasets
      }
    },

    loaded: function () {
      return !this.$_.isEmpty(this.chartDataFromApi);
    },

    chartComponent: function () {
      switch (this.chartType) {
        case 'bar':
          return BarChart;
        case 'line':
          return LineChart;
      }
    },

    chartOptions: function () {

      switch (this.chartType) {
        case 'bar':
          return barChartOptionsGradient;
        case 'line':
          return lineChartOptionsBlue;
      }
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData: function () {
      this.chartDataFromApi = {};
      this.$api.get(this.endpoint).then((resp) => {
        this.$set(this.chartDataFromApi, 'labels', resp.data.labels);
        this.$set(this.chartDataFromApi, 'data', resp.data.data);
      }).catch(() => {
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
      })
    },
  }
}
</script>

<style scoped lang="scss">

</style>
