<template>
  <card class="card-chart" no-footer-line>
    <template slot="header" v-if="title">
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="card-category mb-0 text-uppercase">{{ title }}</h5>
        <octo-date-range-picker v-model="datePickerValue"/>
      </div>
    </template>
    <div class="container-fluid">
      <slot :date-picker-value="datePickerValue"></slot>
    </div>
  </card>
</template>

<script>
import OctoDateRangePicker from "@/components/OctoDateRangePicker";

export default {
  name: "OctoChartContainer",
  components: {
    OctoDateRangePicker,
  },
  data() {
    return {
      datePickerValue: [],
    }
  },
  props: {
    title: {
      type: String,
      default: null,
    },
  },
  computed: {

  },
  mounted() {
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">

</style>
