<template>
  <div class="form-group">
    <el-date-picker
      v-model="datePickerValue"
      type="daterange"
      align="right"
      unlink-panels
      format="dd/MM/yyyy"
      range-separator="-"
      :start-placeholder="$t('fields.date_start')"
      :end-placeholder="$t('fields.date_end')"
      :picker-options="pickerOptions"
    />
  </div>

</template>

<script>
import {DatePicker} from "element-ui";

export default {
  name: "OctoDateRangePicker",
  components: {
    [DatePicker.name]: DatePicker
  },
  data() {
    return {
      datePickerValue: [],
    }
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    initialValue: {
      type: Array,
      default: () => [],
    },
    datePickerDefaultInterval: {
      type: String,
      default: null
    },
    shortcutsDatePicker: {
      type: Array,
      default: () => ['current-week', 'last-week', 'current-month', 'last-month'],
      description: 'Possible values: last-week'
    }
  },
  watch: {
    datePickerValue: function (val) {
      this.$emit('input', val);
    },
    initialValue: function (val) {
      this.datePickerValue = this.$_.isEmpty(val)
        ? this.getInterval(this.datePickerDefaultInterval || 'current-month')
        : val;
    }
  },
  computed: {
    pickerOptions: function () {
      const shortcuts = [];
      this.$_.each(this.shortcutsDatePicker, (item) => {

        shortcuts.push({
          text: this.$t('chart.' + item),
          onClick: function (picker) {
            picker.$emit(
              'pick',
              this.getInterval(item)
            );
          }.bind(this)
        })

      })

      return {
        shortcuts: shortcuts,
        firstDayOfWeek: 1
      };
    }
  },
  mounted() {
    this.datePickerValue = this.$_.isEmpty(this.initialValue)
      ? this.getInterval(this.datePickerDefaultInterval || 'current-month')
      : this.initialValue;
  },
  methods: {
    getInterval: function (item) {
      switch (item) {
        case 'current-week':
          return [
            this.$moment().startOf('week').format(),
            this.$moment().endOf('week').format()
          ];
        case 'last-week':
          return [
            this.$moment().subtract(1, 'weeks').startOf('week').format(),
            this.$moment().subtract(1, 'weeks').endOf('week').format()
          ];
        case 'current-month':
          return [
            this.$moment().startOf('month').format(),
            this.$moment().endOf('month').format()
          ];
        case 'last-month':
          return [
            this.$moment().subtract(1, 'months').startOf('month').format(),
            this.$moment().subtract(1, 'months').endOf('month').format()
          ];
        default:
          return [];

      }
    }
  }
}
</script>

<style scoped>

</style>
